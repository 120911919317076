import React, { useEffect } from "react";
import { navigate  } from "gatsby"
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import { graphql } from "gatsby"
import loadable from '@loadable/component'
import Header from "../Components/Header/Header";
//import Footer from "../Components/Footer/Footer";
import SEO from "../Components/Seo/seo";
import { UserObjectStoreProvider } from "@starberry/myaccount-website-utils";
import { StaticImage } from "gatsby-plugin-image"

import BackButton from "../Components/PropertyDetails/BackButton/BackButton";
import PropertyBanner from "../Components/PropertyDetails/PropertyBanner/PropertyBanner";
import PropertyFeatures from "../Components/PropertyDetails/PropertyFeatures/PropertyFeatures";

const FormBlock = loadable(() => import("../Components/PropertyDetails/FormBlock/FormBlock"))
const AreaGuide = loadable(() => import("../Components/PropertyDetails/AreaGuide/AreaGuide"))
const PropertyList = loadable(() => import("../Components/PropertyDetails/PropertyList/PropertyList"))
const LocRatingMap = loadable(() => import("../Components/map/loc-rating-map-details"))
const StickyCTA = loadable(() => import("../Components/sticky-cta"))
const Footer = loadable(() => import("../Components/Footer/Footer"))

const PROPERTY_DETAILS = gql`
  query GetProperty ($id: String!){  
    properties(where:{id: $id}) {
      id
      title
      display_address
      search_type
      status
      price
      price_qualifier
      images
      publish
      imagetransforms
      floorplan
      department
      virtual_tour
      brochure
      latitude
      longitude
      bedroom
      bathroom
      reception
      room_details
      description
      crm_negotiator_id
      address
      building
    }
  }
`;

const PropertyDetailsTemplate = (props) => {

    // 
    const pageurl = typeof window !== 'undefined' ? window.location.href : ''
    let myid_filt = pageurl.split('?');
    let mypageurl = myid_filt[0];
    let property_slug = mypageurl.split('-');
    let id = property_slug[property_slug.length - 1];
    id = id.replace("/", "");

    const { loading, error, data: property_details } = useQuery(PROPERTY_DETAILS, {
        variables: { id: id }
    });
    const calculatorList = props?.data?.glstrapi?.globalConfig?.PropertyDetailsCalculators;    
    useEffect(() => {
      if (property_details?.properties) {
        if(property_details?.properties[0].publish === false)
        navigate("/404", { replace: true })
      }
    }, [property_details])
    
    // 
    if (loading) return (
        <section className={"loader-wrapper"}>
          <div id="loader-wrapper">
            <div id="loader" class="new-loader">
              <StaticImage src="../images/logo.png" alt="logo" loading="eager" imgClassName="logo-white loader-logo" />
            </div>
          </div>
        </section>
    );

    let isPrerender = false;
    if (typeof window !== "undefined" && window?.navigator) {
      isPrerender = /HeadlessChrome/.test(window?.navigator?.userAgent)
    }

    return (
        <React.Fragment>
         

            <div className="wrapper property-wrap primary-icon-template detail-property">
                <div className="inner-header"><Header /></div>

                {
                    property_details && property_details.properties && property_details.properties.length > 0 ? property_details.properties.map((data, i) => {
                        if(!data.publish) return null
                        
                        // Get Map Details
                        let mapItems = [];
                        let mapItem = {};
                        mapItem['lat']  = data.latitude      
                        mapItem['lng']  = data.longitude
                        mapItem['id']  = data.id
                        const mapMarkerImageShowFlag = 0;
                        mapItems.push( mapItem );
                        // Get Map Details
                        const metaTitle = data.title + ' in ' + data.display_address
                        var newPropertyType = ""
                        if(data.department == "new_developments") {
                          newPropertyType= "new "
                        }
                        const metaDesc= 'Explore in detail, '+data.bedroom+' bedroom '+newPropertyType+data.building+' '+data.status.toLowerCase()+' in '+data.display_address+' with Balgores at £'+data.price.toLocaleString()+'. Contact us and get assistance in finding the perfect property.';
                      
                      
                        return (
                            <>
                                <SEO image={data.images && data.images[0] && data.images[0].url} title={metaTitle!=undefined&&metaTitle} description={metaDesc!=undefined&&metaDesc} />	
                                <BackButton search_type={data.search_type} department={data.department}/>
                                <PropertyBanner propertyData={data} />
                                <PropertyFeatures propertyData={data} calculatorData={calculatorList} />
                                {/* {data.department !="new_developments" &&
                                <FormBlock search_type={data.search_type} propertyValue={data.price}/>
                                } */}
                                {data.longitude && data.latitude &&
                                <div className="property-details-landing-map" id="property-details-map">
                                  <div className="calc-bottom-sec"></div>
                                    <LocRatingMap data={mapItems} />
                                </div>
                                }
                                <AreaGuide postcode={data && data.address && data.address.postcode && data.address.postcode.split(" ")[0]} />
                                <PropertyList search_type={data.search_type} department={data.department} id={data.id} address ={data && data.address} />
                                <StickyCTA type="link" link={`/book-a-viewing?pid=${data.id}`}label="Book a viewing"/>
                                <Footer searchtype={`details`} ptype={data.search_type} property_building={data.building} searchBedroomfield={data.bedroom} property_address={data.address} display_address={data.display_address} />
                            </>
                        )
                    }) : ''
                    
                }

                
            </div>
        </React.Fragment>
    )
}

// export default PropertyDetailsTemplate
export const query = graphql`
  query {
    glstrapi {
      globalConfig {
        PropertyDetailsCalculators {
            id 
            Name
            URL     
            StraplineText
            BoxBg
        }
      }
    }
  }
`;

const PropertyDetailsTemplateComp = (props) => {
  return (
    <UserObjectStoreProvider>
      <PropertyDetailsTemplate {...props} />
    </UserObjectStoreProvider>
  )
}

export default PropertyDetailsTemplateComp