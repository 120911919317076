import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import {Button, Form, Container, Row, Col,Dropdown} from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import { SaveItem,UserObjectStoreProvider, useUserObjectState, useAuthState } from "@starberry/myaccount-website-utils";
// markup
const PropertyHeading = (props) => {

  const { state, services } = useUserObjectState()
  const { services: authServices } = useAuthState()
  const isAuthenticated = authServices.isAuthenticated()

  useEffect(() => {
    if (isAuthenticated) {
    // on page load get the users saved objects
        services.getUserobject({ type: "property", state:true })
    }
  }, [])


  return (
        <React.Fragment>
          {
            props?.propertyHeadingData &&
              <div className="property-title">
                <div className="">
                <h1 className="main-heading">{props?.propertyHeadingData?.display_address}</h1>
                {/* <h1 className="side-heading d-none">{props?.propertyHeadingData?.title}</h1> */}
                <div className="price-block">
                  {props.propertyHeadingData && props.propertyHeadingData.price_qualifier !== "Price On Application" && <span className="price">£{props?.propertyHeadingData?.price.toLocaleString()}</span>}
                  <small>{props.propertyHeadingData.search_type=== "sales" ? props.propertyHeadingData.price_qualifier:"pcm"}</small>
                </div>
                </div>
                <div className="search-save">
                  <SaveItem type="property" pid={props.id}>
                    <div className="like-btn">
                      <i className="icon-heart-outline"></i> <span>Save this search</span>
                    </div>
                  </SaveItem>
                  {props.propertyHeadingData.status==="Sold" ?
                  <Link to={`/book-a-viewing?pid=${props?.propertyHeadingData?.id}&type=enquiry`} className="btn btn-enquiry d-lg-block">Make an enquiry</Link>
                  :
                  <Link to={`/book-a-viewing?pid=${props?.propertyHeadingData?.id}`} className="btn btn-enquiry d-lg-block">Book a viewing</Link>
                  }
                  </div>
              </div>
          }
        </React.Fragment>
    )
}
export default PropertyHeading
