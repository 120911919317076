import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import {Button, Form, Container, Row, Col,Dropdown} from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import "./BackButton.scss"
import $ from 'jquery';
import { SaleURL, LetURL, NewHomeSaleURL, NewHomesLetURL  } from "../../utils";

// markup
const BackButton = (props ) => {
    
    const handleBack = () => {
        $(window).scrollTop(0);
        window.history.back();        
    }

  return ( 
        <React.Fragment>
           <div className="back-block">
               <Container>
                   <Row>
                       <Col md="12">
                           <div className="back-wrap">
                           {/* <a href="javascript:void(0)" className="back-btn" onClick={handleBack}><i className="icon-arrow-left"></i> Back to property listings</a> */}
                           {props.department === "new_developments"?
                           <Link to={props.search_type === "lettings"?NewHomesLetURL+'/essex-and-kent':NewHomeSaleURL+'/essex-and-kent'} className="back-btn"> <i className="icon-arrow-left"></i> Back to property listings</Link>
                           :
                           <Link to={props.search_type === "lettings"?LetURL+'/essex-and-kent':SaleURL+'/essex-and-kent'} className="back-btn"> <i className="icon-arrow-left"></i> Back to property listings</Link>
                            }
                           </div>
                       </Col>
                   </Row>
               </Container>
           </div>
        </React.Fragment>
    )
}
export default BackButton