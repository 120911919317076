import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Button, Form, Container, Row, Col, Dropdown, Modal } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import $ from "jquery";
import getVideoId from 'get-video-id';
import PlayVideo from "../../../Components/Play/PlayVideo";
import PropertyHeading from "../PropertyHeading";
// import Banner375 from "../../../images/property-details/banner375.jpg";
// import Banner768 from "../../../images/property-details/banner768.jpg";
// import Banner1400 from "../../../images/property-details/banner1400.jpg";
// import Banner1600 from "../../../images/property-details/banner1600.jpg";
// import Banner2 from "../../../images/property-details/banner2.jpg";
// import Banner2_1600 from "../../../images/property-details/banner2_1600.jpg";
// import Banner3 from "../../../images/property-details/banner3.jpg";
// import Banner3_1600 from "../../../images/property-details/banner3_1600.jpg";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform"
import { UserObjectStoreProvider, useUserObjectState, useAuthState, getUserobjectData } from "@starberry/myaccount-website-utils";
import NoImage from "../../../images/no-image.png"


import "./PropertyBanner.scss"
import PlayCustomVideo from "../../Play/custom-video";
// markup
const PropertyBanner = (props) => {
  const [isPlay, setPlay] = useState(false);
  const [VideoID, setVideoID] = useState(false);

  // Video
  const [show, setShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }
  // Video

  const [userObjects, setUserObjects] = useState({});
  const { state: authState, services: authServices } = useAuthState()
  const isAuthenticated = authServices.isAuthenticated()



  // Property images lightbox
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openPropertyImage = (e, ind) => {
      e.preventDefault();
      setPhotoIndex(ind);
      setIsOpen(true);
  }
  // Property images lightbox

  // Property floorplan images lightbox
  const [photoIndexFloorplan, setPhotoIndexFloorplan] = useState(0);
  const [isOpenFloor, setIsOpenFloor] = useState(false);

  const openPropertyImageFloor = (e, ind) => {
      e.preventDefault();
      setPhotoIndexFloorplan(ind);
      setIsOpenFloor(true);
  }
  // Property floorplan images lightbox

  // Property details images lightbox
  const propertyImages = props?.propertyData?.images
  var propertyLightImages = propertyImages && propertyImages.map(img => img.srcUrl);
  // Property details images lightbox

  // Property details floorplan images lightbox
  const floorplanImages = props?.propertyData?.floorplan
  var propertyLightImagesFloorplan = floorplanImages && floorplanImages.map(img => img.srcUrl);
  // Property details floorplan images lightbox

  // Video id
  const videoid = props.propertyData.virtual_tour && props.propertyData.virtual_tour.length > 0 && getVideoId(props.propertyData.virtual_tour[0].url);
  var video_url=""
  if(videoid) {
    video_url = "https://www.youtube-nocookie.com/embed/" + videoid.id;
  }
  // Video id

  useEffect(() => {
    $(".propertyMap").click(function() {
      $('html, body').animate({
          scrollTop: $("#property-details-map").offset().top
      }, 1000);
    })
    if (isAuthenticated) {
      const getUserObjects = async () => {
          try {
              const userObjects = await getUserobjectData()
              setUserObjects(userObjects.data.data)
          } catch (e) {
              console.log("e:", e)
          }
      }
      getUserObjects()
    }
  },[]);
  let processedImages = JSON.stringify({});
  if (props?.propertyData?.imagetransforms?.images_Transforms) {
      processedImages = props?.propertyData?.imagetransforms.images_Transforms;
  }  
  //console.log('aaaaaaa',props.propertyData.virtual_tour, videoid)  
  return (
    <React.Fragment>
      <UserObjectStoreProvider>
      {
        props?.propertyData &&
        <>
          <div className="property-banner">
            <Container>
              <Row>
                <Col md="12">
                  <div className="d-none d-md-block">
                    <PropertyHeading propertyHeadingData={props?.propertyData} id={props?.propertyData?.id} />
                  </div>
                </Col>
                <Col md="12">
                  
                    <div className="banner-img mlr-minus">
                      <div className="banner-left">
                        <picture className="img-zoom">
                          {/* <source media="(min-width:1600px)" srcSet={Banner1600} />
                          <source media="(min-width:992px)" srcSet={Banner1400} />
                          <source media="(min-width:768px)" srcSet={Banner768} /> */}
                          {/* <img src={props?.propertyData?.images[0].srcUrl} alt="Banner Img" /> */}
                          {props.propertyData.images[0] ?
                          <ImageTransform imagesources={props.propertyData.images[0] && props.propertyData.images[0].url} renderer="srcSet" imagename="property.images.detail"attr={{ alt: props?.propertyData?.display_address+ ' -  Balgores' }}
                        imagetransformresult={processedImages} id={props?.propertyData?.id} testparam={true} />
                        :
                        <img src={NoImage} alt= {props?.propertyData?.display_address + ' - Balgores'} />
                        }
                        </picture>
                        {props.propertyData.status &&
                        <span className="property-tag">
                          {props?.propertyData?.status}
                        </span>
                        }
                        <div className="property-icons d-md-flex">
                          {
                            props?.propertyData?.images &&
                            <a href="javascript:void(0)" className="zoom" onClick={(e) => openPropertyImage(e, 0)}>
                              <i className="icon-zoom">
                              </i>
                              <span className="gall-icon">{props?.propertyData?.images.length} photos</span>
                            </a>
                          }
                          {
                            props?.propertyData?.floorplan && props?.propertyData?.floorplan.length > 0 &&
                            <a href="javascript:void(0)" className="zoom" onClick={(e) => openPropertyImageFloor(e, 0)}>
                              <i className="icon-floor"></i>
                              <span>Floorplan</span>
                            </a>
                          }
                          {
                            props?.propertyData?.virtual_tour && props?.propertyData?.virtual_tour.length > 0 && props?.propertyData?.virtual_tour[0].url &&
                            (
                              videoid?.service === "youtube" ?
                              <PlayCustomVideo url={video_url} />
                              :
                              <a href="javascript:void(0)" className="zoom" onClick={() => handleShow()}>
                                <i className="icon-video"></i>
                                <span>Virtual tour</span>
                              </a>
                            )
                          }
                          {
                            props?.propertyData?.latitude &&
                            <a href="javascript:void(0)" className="zoom propertyMap">
                              <i className="icon-map_line"></i>
                              <span>Map</span>                    
                            </a>
                          }
                        </div>
                      </div>
                      <div className="banner-right d-none d-lg-flex">
                        <div className="banner-right-top img-zoom">
                          <picture>
                            {/* <source media="(min-width:1600px)" srcSet={Banner2_1600} />
                            <source media="(min-width:992px)" srcSet={Banner2} />
                            <source media="(min-width:768px)" srcSet={Banner2} /> */}
                            {/* <img src={props?.propertyData?.images[1].srcUrl} alt="Banner Img" /> */}
                            {props.propertyData.images && props.propertyData.images[1] != null &&
                            <ImageTransform imagesources={props?.propertyData?.images[1].url} renderer="srcSet" imagename="property.images.detail2"attr={{ alt: props?.propertyData?.display_address+ 'BannerImg2 -  Balgores' }}
                        imagetransformresult={processedImages} id={props?.propertyData?.id} testparam={true} />
                            }
                          </picture>
                        </div>
                        <div className="banner-right-bottom img-zoom">
                          <picture>
                            {/* <source media="(min-width:1600px)" srcSet={Banner3_1600} />
                            <source media="(min-width:992px)" srcSet={Banner3} />
                            <source media="(min-width:768px)" srcSet={Banner3} /> */}
                            {/* <img src={props?.propertyData?.images[2].srcUrl} alt="Banner Img" /> */}
                            {props.propertyData.images && props.propertyData.images[2] != null &&
                            <ImageTransform imagesources={props?.propertyData?.images[2].url} renderer="srcSet" imagename="property.images.detail2"attr={{ alt: props?.propertyData?.display_address+ 'BannerImg3 -  Balgores' }}
                        imagetransformresult={processedImages} id={props?.propertyData?.id} testparam={true} />
                            }
                          </picture>
                          {
                            props?.propertyData?.virtual_tour && props?.propertyData?.virtual_tour.url &&
                            (
                              videoid?.service === "youtube" ?
                              <PlayCustomVideo url={props?.propertyData?.virtual_tour?.url} tag="pictureVideo" />
                              :
                              <button
                                type="button"
                                className="btn-play"
                                onClick={() => handleShow()}
                              >
                                <i className="icon-play"></i>
                              </button>
                            )
                          }
                        </div>
                      </div>
                      <div className="d-block d-sm-none mobile-detail-head">
                    <PropertyHeading propertyHeadingData={props?.propertyData} />
                  </div>
                    </div>
                  
                </Col>

              </Row>
            </Container>
          </div>

          {/* Video modal */}
          <Modal
            show={show}
            fullscreen={fullscreen}
            onHide={() => setShow(false)}
            dialogClassName="modal-fullscreen property-details-modal"
          >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className="p-0">
              <iframe src={props?.propertyData?.virtual_tour[0]?.url}></iframe>
            </Modal.Body>
          </Modal>
          {/* Video modal */}

          {/* Property image lightbox */}
          {isOpen && (
              <Lightbox
                  mainSrc={propertyLightImages[photoIndex]}
                  nextSrc={propertyLightImages[(photoIndex + 1) % propertyLightImages.length]}
                  prevSrc={propertyLightImages[(photoIndex + propertyLightImages.length - 1) % propertyLightImages.length]}
                  onCloseRequest={() => setIsOpen(false)}
                  onMovePrevRequest={() =>
                      setPhotoIndex((photoIndex + propertyLightImages.length - 1) % propertyLightImages.length)
                  }
                  onMoveNextRequest={() =>
                      setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
                  }
              />
          )}
          {/* Property image lightbox */}

          {/* Property floorplan image lightbox */}
          {isOpenFloor && (
              <Lightbox
                  mainSrc={propertyLightImagesFloorplan[photoIndex]}
                  nextSrc={propertyLightImagesFloorplan.length > 1 && propertyLightImagesFloorplan[(photoIndex + 1) % this.props.floorplanimages.length]}
                  prevSrc={propertyLightImagesFloorplan.length > 1 && propertyLightImagesFloorplan[(photoIndex + propertyLightImagesFloorplan.length - 1) % propertyLightImagesFloorplan.length]}
      
      
                  onCloseRequest={() => setIsOpenFloor(false)}
                  onMovePrevRequest={() =>
                      setPhotoIndex((photoIndex + propertyLightImagesFloorplan.length - 1) % propertyLightImagesFloorplan.length)
                  }
                  onMoveNextRequest={() =>
                      setPhotoIndex((photoIndex + 1) % propertyLightImagesFloorplan.length)
                  }
              />
          )}
          {/* Property floorplan image lightbox */}

          {/* {VideoID &&
            <PlayVideo
              isOpen={isPlay}
              isCloseFunction={setPlay}
              videoId={VideoID}
              isAutoPlay={1}
            />
          } */}
        </>
      }
      </UserObjectStoreProvider>
    </React.Fragment>
  )
}
export default PropertyBanner