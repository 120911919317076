import React from "react";
import { Link } from "gatsby";
import "./HomeBuyingBlock.scss";

const HomeBuyingBlock = props => {
    return (
        <div className="home-buying-module">
            <div className="left-block">
                <h2>Home buying with a helping hand</h2>
                <p>
                    Everything you need to buy your next home, whenever you need it.
                </p>
            </div>
            <div className="right-block">
                {props?.url &&
                 <Link to={props.url} className="btn">
                    Get Started
                 </Link>
                }
            </div>
        </div>
    );
}
export default HomeBuyingBlock;