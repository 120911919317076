import React from "react";
import { Link } from "gatsby"
import "./CalculatorCard.scss";

const CalculatorCard = ({calculator}) => {
    const boxClass = calculator?.BoxBg ? `boxbg-${calculator.BoxBg.toLowerCase()}`: '';
    const toolLink = calculator?.HideDetailsPage ? calculator?.Custom_Tool_Link : `/financial-services/calculators/${calculator?.URL}/`;

    return(
       <div className={`calculator-card-wrap ${boxClass}`}>
            <div className="calculator-card-body">
                <h3 className="calculator-card-title">{calculator?.Name}</h3>
                <div className="calculator-card-text">{calculator?.StraplineText}</div>
                <div className="calculator-card-btn">
                    <Link to={toolLink} className="btn btn-primary">View tool</Link>
                </div>                        
            </div>  
       </div>
    )
}
export default CalculatorCard;