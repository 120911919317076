import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import CalculatorCard from "./CalculatorCard";
import "./CalculatorList.scss";

const CalculatorList = ({GQLCalculators} ) => {
    return(
       <div className="calculator-list-wrap">
        <Container>        
            <Row> 
                {GQLCalculators.map((calculator) => {
                    const uniqueClassName = 'calc-' + (calculator?.Name?.toLowerCase().replace(/\s+/g, '-') || '');
                    return (
                        <Col key={calculator.id} md={6} lg={4} className={`calculator-card-item ${uniqueClassName}`}>
                            <CalculatorCard calculator={calculator} />
                        </Col>
                    );
                })}
             </Row>
        </Container>
       </div>
    )
}
export default CalculatorList;