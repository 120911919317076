import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Button, Form, Container, Row, Col, Dropdown } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import HTMLReactParser from 'html-react-parser';
import ReactReadMoreReadLess from "react-read-more-read-less";
import PropertyHeading from "../PropertyHeading";
import RightBlock from "../RightBlock/RightBlock";
import CalculatorList from '../../StaticModules/CalculatorList/CalculatorList';
import './PropertyFeatures.scss';
import Sticky from 'react-stickynode';
import $ from "jquery";
// markup
const PropertyFeatures = (props) => {
    const [ClientWidth, setClientWidth] = useState(false);
    useEffect(() => {
      if($(window).width() > 1600 ) {
        setClientWidth(true);
      }
    })

    return (
        <React.Fragment>
            {
                props?.propertyData &&
                <div className="property-feature">
                    <Container>
                        <Row>
                            <Col md="12 d-md-none">
                                <PropertyHeading />
                            </Col>
                                <Col md="12" lg="7" xl="8">
                                
                                    <div className="features-key">
                                        <h3>Key Features & Description</h3>
                                        <ul>
                                            {
                                                props?.propertyData?.bedroom > 0 &&
                                                <li>
                                                    {
                                                        props?.propertyData?.bedroom > 1 ? props?.propertyData?.bedroom+ " Bedrooms" : props?.propertyData?.bedroom+ " Bedroom"
                                                    }
                                                </li>
                                            }
                                            {
                                                props?.propertyData?.bathroom > 0 &&
                                                <li>
                                                    {
                                                        props?.propertyData?.bathroom > 1 ? props?.propertyData?.bathroom+ " Bathrooms" : props?.propertyData?.bathroom+ " Bathroom"
                                                    }
                                                </li>
                                            }
                                            {
                                                props?.propertyData?.reception > 0 &&
                                                <li>
                                                    {
                                                        props?.propertyData?.reception > 1 ? props?.propertyData?.reception+ " Reception Rooms" : props?.propertyData?.reception+ " Reception Room"
                                                    }
                                                </li>
                                            }
                                            {/* <li>3 Bedrooms</li>
                                            <li>3 Bathrooms</li>
                                            <li>2 Reception Rooms</li>
                                            <li>22’ Living Room</li>
                                            <li>Master Bedroom with En-suite</li>
                                            <li>78’ Rear Garden with Outbuildings</li>
                                            <li>3 Bedrooms</li>
                                            <li>3 Bathrooms</li>
                                            <li>2 Reception Rooms</li>
                                            <li>22’ Living Room</li> */}
                                        </ul>
                                    </div>
                                    <div className="property-description">
                                        {
                                            props?.propertyData?.room_details &&
                                            props?.propertyData?.room_details.map((roomData) => {
                                                return (
                                                    <p><strong>{roomData.name}</strong> {roomData.description}</p>
                                                )
                                            })
                                        }
                                        {
                                            props?.propertyData?.description &&
                                            <p>{HTMLReactParser(props?.propertyData?.description)}</p>
                                        }
                                        {/* <p> <strong>Panelled Entrance Door to Entrance Hall</strong> 19'4 x 9'6.Obscure double glazed windows to front, stairs to first floor with under stair storage cupboard, further storage cupboard, wood flooring with under floor heating, smooth ceiling, doors to accommodation.</p>
                                        <p><strong>Ground Floor Cloakroom</strong> Obscure double glazed window to side. Suite comprising: feature bowl style wash hand basin with mixer tap and storage under, low level wc with push flush. Tiled flooring with under floor heating, complementary tilling, smooth ceiling.</p> */}
                                    </div>
                                    {/* <a href="#" class="link-text">Read more</a> */}
                                    <div className="property-calculator">
                                        <CalculatorList GQLCalculators={props?.calculatorData} />
                                    </div>                                    
                                </Col>
                                <Col md="6" lg="5" xl="4" className="d-lg-block">
                                {ClientWidth ?
                                <Sticky top=".header" bottomBoundary=".calc-bottom-sec">
                                
                                <RightBlock brochure={props?.propertyData?.brochure} negcrm_id={props?.propertyData?.crm_negotiator_id} propertyid={props?.propertyData?.id}/>
                                
                                </Sticky>
                                :
                                
                                    <RightBlock brochure={props?.propertyData?.brochure} negcrm_id={props?.propertyData?.crm_negotiator_id} propertyid={props?.propertyData?.id}/>
                                
                                }
                                </Col>
                        </Row>
                    </Container>
                </div>
            }
        </React.Fragment>
    )
}
export default PropertyFeatures